define("discourse/plugins/discourse-chart/initializers/add-chart-ui-builder", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-chart/discourse/components/modal/chart-ui-builder"], function (_exports, _pluginApi, _chartUiBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeChartUIBuilder(api) {
    const siteSettings = api.container.lookup("service:site-settings");
    const modal = api.container.lookup("service:modal");
    api.addComposerToolbarPopupMenuOption({
      label: "chart.ui_builder.title",
      icon: "chart-line",
      condition: () => {
        return siteSettings.discourse_chart_enabled;
      },
      action: toolbarEvent => {
        modal.show(_chartUiBuilder.default, {
          model: {
            toolbarEvent
          }
        });
      }
    });
  }
  var _default = _exports.default = {
    name: "add-chart-ui-builder",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.13.0", initializeChartUIBuilder);
    }
  };
});